<template>
  <div class="container-shinua">
    <div class="header">
      <h1>טופס מילוי לשינוע</h1>
    </div>

    <div class="fields">
      <div class="field">
        <p>שם הסניף המזמין</p>
         <el-select style="width:100%;" v-model="values.selectedBranchFrom" filterable placeholder="בחר/חפש סניף מתוך הרשימה">
            <el-option
              v-for="branche in branches"
              :key="branche"
              :value="branche"
            >
            </el-option>
          </el-select>
      </div>
      <div class="field">
        <p>לאן מעבירים</p>
         <el-select style="width:100%;" v-model="values.selectedBranchTo" filterable placeholder="בחר/חפש סניף מתוך הרשימה">
            <el-option
              v-for="branche in branches"
              :key="branche"
              :value="branche"
            >
            </el-option>
          </el-select>
      </div>
      <div class="field">
        <p>פרטי דרישת השינוע</p>
        <Textarea
          v-model="values.comments"
          :autoResize="true"
          rows="5"
          cols="30"
          style="width:100%"
          placeholder="מלל חופשי..."
        />
      </div>
      <div class="field">
        <p>מידת הדחיפות</p>
        <Dropdown
          v-model="values.urgency"
          :options="urgencyList"
          placeholder="מידת הדחיפות"
          style="width:100%;"
          scrollHeight="260px"
        />
      </div>
      <div class="field">
        <p>שם פותח הקריאה</p>
        <InputText
          type="text"
          v-model="values.name"
          style="width:100%"
          placeholder="שם מלא"
        />
      </div>
      <div class="field">
        <p>טלפון נייד ליצירת קשר</p>
        <InputText
          type="text"
          v-model="values.phone"
          style="width:100%"
          placeholder="טלפון נייד"
        />
      </div>
      <div class="field">
        <p>דוא"ל לעדכון התהליך</p>
        <InputText
          type="text"
          v-model="values.email"
          style="width:100%"
          :placeholder="user.email"
        />
      </div>
    </div>
    <div class="images">
      <template v-for="(url_obj,index) in values.images" :key="index">
        <div v-loading="reload_image" class="image">
          <i v-if="!url_obj.url" @click="handle_open_file_mode(url_obj)" class="material-icons add_icon">photo_camera</i>
          <img v-if="url_obj.url" :src="url_obj.url">
          <div @click="handle_remove_image(url_obj)" v-if="url_obj.url" class="remove-btn">
            <i class="material-icons remove-icon">delete</i>
          </div>
        </div>
      </template>
    </div>


    <input ref="input_ref" type="file" v-show="false" @change="handle_upload_image">
    <Button
      label="שלח"
      class="p-button-success"
      style="width:100%; margin-top: 20px"
      @click="handleSubmit"
    />

    <!-- JUST FOR MAIL -->
    <div class="form-email" id="form-email" v-show="false">
      <table
        style="width:50%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תאריך הבקשה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.createdAt.toLocaleDateString('he') }}-{{ values.createdAt.toLocaleTimeString('he') }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding:8px;">
            שם הסניף השולח
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding:8px;">
            {{ values.selectedBranchFrom }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            לסניף
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.selectedBranchTo }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם המבקש/ת
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.name }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            מידת הדחיפות
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.urgency }}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            טלפון ליצירת קשר
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.phone }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            דוא"ל
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.email }}
          </td>
        </tr>
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תיאור הבקשה
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ values.comments }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Textarea from 'primevue/textarea';
import swal from "sweetalert";
import {projectFirestore,projectFunctions} from '../../../../firebase/config'
import { computed, onMounted, ref } from 'vue';
import store from '../../../../store';
import {get_branches_from_db} from '../../../../Methods/rosman_shivuk_funcs'
import { slide_pop_error } from '../../../../Methods/Msgs';
import upload_image_storage from '../../../../Methods/use_general_storage';

export default {
    components:{Dropdown,InputText,Textarea},
    setup(){

        const {url,uploadImage,delete_image_by_url} = upload_image_storage()

        const selected_url_obj = ref(null)

        const reload_image = ref(false)

        const input_ref = ref()

        const error=ref('')
       
        const urgencyList=[
            'לא דחוף - בשינוע הבא',
            'דחוף - עד 48 שעות',
            'בהול - עד 24 שעות'
        ]

        const user = ref(computed(() => {
            if(store.getters.user){
                return store.getters.user
            }else{
                return 'ממתין'
            }
        }))

        const handle_open_file_mode = (url_obj) => {
          selected_url_obj.value = url_obj
          input_ref.value.value
          input_ref.value.click()
        }

        const handle_upload_image = async(e) => {
          try{
            reload_image.value = true
            const type = ['image/png','image/jpg','image/jpeg']
            const selected = e.target.files[0]
            if(selected && type.includes(selected.type)){
              const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
              if (selected.size > maxSizeInBytes) {
                throw new Error('עליך להעלות תמונה שמשקלה לא עולה על 5mb');
              }
              await uploadImage(selected,`Shinua/${new Date()}`)
              selected_url_obj.value.url = url.value
              reload_image.value = false
              // Reset file input value
              e.target.value = null;
            }else{
              throw new Error('עליך להעלות קובץ בפורמט PDF או JPG בלבד!')
            }
          }catch(err){
            reload_image.value = false
             slide_pop_error(err.message)
          }
        }

        const handle_remove_image = async(url_obj) => {
          try{
            await delete_image_by_url(url_obj.url)
            url_obj.url = ''
          }catch(err){
            slide_pop_error(err.message)
          }
        }

        const handleSubmit=async()=>{
            if(!validation()){
                 swal("בעיה",`${error.value}`, "warning");
            }else{
                const doc= projectFirestore.collection("Applications").doc("oRfkXmNfGOHesWFGYwnN")
                  .collection("Shinua")
                  .doc()
                  await doc.set({...values.value,id:doc.id})
                  sendEmailToUser()
                  sendEmailToCharge()
                  clearData()
                  swal("הצלחה","הטופס נשלח בהצלחה", "success");
            }
        }
        
        function sendEmailToUser() {
        projectFunctions.httpsCallable('send_email')({
            html: `<div>
                <h3>
                    שלום ${values.value.name},
                </h3>
                <h3>
                    הזמנת שינוע מתאריך ${values.value.createdAt.toLocaleDateString('he')}-${values.value.createdAt.toLocaleTimeString('he')}
                </h3>
                <h3>
                    מסניף/אתר ${values.value.selectedBranchFrom}
                </h3>
                <h3>
                    לסניף/אתר ${values.value.selectedBranchTo}
                </h3>
                <h3>
                    נשלחה בהצלחה
                </h3>
                <h3>
                    תיאור הבקשה: ${values.value.comments}
                </h3>
                <h3 style="color:red;">
                    נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
                </h3>
            </div>`,
            destinations:[`${values.value.email}`],
            from:"מערכת שינוע והובלה",
            title:"בקשת שינוע חדשה"
        }) 
        }

        function sendEmailToCharge() {
          projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:["eldad@rosman.co.il,vladsmalovich95@gmail.com","meirb.rosman@gmail.com"],
              from:"מערכת שינוע והובלה",
              title:"בקשת שינוע חדשה"
          })
        }
       
        const values=ref({
            selectedBranchFrom:'',
            selectedBranchTo:'',
            comments:'',
            urgency:'',
            name:'',
            phone:'',
            email: '',
            status:'חדש',
            createdAt: new Date(),
            images:[
              {
                url:''
              },
              {
                url:''
              },
              {
                url:''
              },
            ]
        })


        const validation=()=>{
            if(!values.value.email.includes('@'))values.value.email=user.value.email
            for (var key in values.value) {
                if(key=='selectedBranchFrom'){
                    if(values.value[key].length==0){
                        error.value='חסר סניף המזמין'
                        return false
                    }
                }
                if(key=='selectedBranchTo'){
                    if(values.value[key].length==0){
                        error.value='נא למלא לאן מעבירים את השינוע'
                         return false
                    }
                }
                if(key=='comments'){
                    if(values.value[key].length==0){
                        error.value='נא למלא את פרטי דרישות השינוע'
                         return false
                    }
                }
                if(key=='urgency'){
                    if(values.value[key].length==0){
                        error.value='נא למלא את שדה מידת הדחיפות'
                         return false
                    }
                }
                if(key=='name'){
                    if(values.value[key].length==0){
                        error.value='חסר שם סניף המזמין'
                        return false
                    }
                }
                if(key=='phone'){
                    if(values.value[key].length==0){
                        error.value='חסר טלפון ליצירת קשר'
                        return false
                    }
                }
                if(key=='email'){
                    if(values.value[key].length==0){
                        error.value='חסרה כתובת מייל'
                        return false
                    }
                }
            }
            return true
        }
        const clearData=()=>{
            values.value = {
                selectedBranchFrom:'',
                selectedBranchTo:'',
                comments:'',
                urgency:'',
                name:'',
                phone:'',
                email: '',
                status:'חדש',
                createdAt: new Date(),
                images:[
                  {
                    url:''
                  },
                  {
                    url:''
                  },
                  {
                    url:''
                  },
                ]
            }
        }


        const branches = ref(['משרדים','אחר']);

        onMounted(async()=>{
          branches.value = [...await get_branches_from_db(),...branches.value]
        })
        return{
          error,
          branches,
          urgencyList,
          values,
          user,
          handleSubmit,
          input_ref,
          handle_open_file_mode,
          handle_upload_image,
          reload_image,
          handle_remove_image,
        }
    }
}
</script>

<style scoped>
    .container-shinua{
         width: 70vw;
         height: 75vh;
         margin: 0 auto;
         background: black;
         padding: 1rem;
         color: black;
         background: #fff;
      
         
    }
    @media only screen and (max-width: 600px) {
        .container-shinua{
            width: 100vw;
            height: 100vh;
            max-height: calc(100vh - 5rem);
            overflow-y: scroll;
        }  
    }
    .fields .field{
        width: 100%;
        margin-bottom: 5px;
    }
    .header{
        display: flex;
        justify-content: space-between;
    }
    .images{
      width: 100%;
      height: 200px;
      display: grid;
      grid-template-columns: repeat(3,1fr);
      grid-gap: 2px;
    }
    .image{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px solid lightgray;
    }
    .image img{
      max-width: 100%;
      max-height: 100%;
    }
     @media only screen and (max-width: 600px) {
      .images{
        height: auto;
        grid-template-columns: repeat(1,1fr);
        grid-auto-rows: 150px;
      }
     }

     .add_icon,.remove-icon{
        cursor: pointer;
        color: var(--blue);
        user-select: none;
        
     }
     .remove-icon{
        color: var(--danger);
        font-size: 25px;
     }
     .remove-btn{
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
     }
  
</style>